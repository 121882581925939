import React from 'react';

const VideoTags = () => {
  return (
    <div>
      Video Tags
    </div>
  )
}

export default VideoTags;